import React from 'react';
import './App.css';
import { Linkedin, Twitter, Github, Mail } from 'lucide-react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

function Layout({ content }: { content: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="py-16 max-w-2xl mx-auto min-h-screen flex flex-col">
        <div className="font-semibold text-lg pb-1 cursor-pointer hover:underline" onClick={() => navigate('/')}>Jet New</div>
        <hr />
        <div className="pt-4 flex space-x-12">
          <div className="flex flex-col space-y-1">
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/about')}>About</div>
            <div className="cursor-pointer hover:underline" onClick={() => navigate('/projects')}>Projects</div>
            <a href="https://agentscale.ai" target="_blank" rel="noopener noreferrer" className="cursor-pointer hover:underline">Services</a>
          </div>
          {content}
        </div>
        <div className="pt-4 flex space-x-2 mt-auto">
          <a href="https://www.linkedin.com/in/jetnew" target="_blank" rel="noopener noreferrer">
            <Linkedin />
          </a>
          <a href="https://twitter.com/jetnew_sg" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </a>
          <a href="https://github.com/jetnew" target="_blank" rel="noopener noreferrer">
            <Github />
          </a>
          <a href="mailto:jet@aetherlab.ai" target="_blank" rel="noopener noreferrer">
            <Mail />
          </a>
        </div>
      </div>
    </>
  )
}

function Home() {
  return (
    <Layout content={
      <div className="space-y-4">
        <p>🇸🇬 Hi! I'm an AI engineer based in Singapore, working on the intersection of RAG, agents and knowledge graphs.</p>
        <p>💻 I'm currently building <a href="https://atlas.aetherlab.ai" className="cursor-pointer underline hover:italic">Atlas</a>, a browser assistant that helps people understand complex content better.</p>

        <p>💼 I've worked as:
          <ul className="list-disc pl-8">
            <li>Founder at AgentScale AI (2023): Research, Engineering, Product</li>
            <li>Data Scientist at Indeed (2022): Recommendation Systems</li>
            <li>AI Researcher at NUS (2021): Transformer and Reinforcement Learning</li>
            <li>Machine Learning Engineer at Grab (2021): Bayesian Machine Learning</li>
          </ul>
        </p>

        <p>🎓 I graduated in CS (Turing Programme, University Scholars Programme) from the National University of Singapore in 2023, as top student in machine learning.</p>

        <p>🧠 I run AI Space, an AI community in Singapore with 600+ people, e.g. at Google Developer Space.</p>
      </div>
    } />
  )
}

function About() {
  return (
    <Layout content={
      <div className="space-y-4">
        <div>
          <div>Founder, AI Engineer</div>
          <p className="text-sm">Atlas, Jun 2024 - Present</p>
        </div>
        <div>
          <div>Co-Founder, AI Engineer</div>
          <p className="text-sm">AgentScale AI, Jun 2023 - May 2024</p>
        </div>
        <div>
          <div>Data Scientist</div>
          <p className="text-sm">Indeed, May 2022 - Jul 2022</p>
        </div>
        <div>
          <div>AI Researcher</div>
          <p className="text-sm">National University of Singapore, Nov 2020 - Apr 2022</p>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p className="text-sm">Grab, May 2020 - Jul 2020</p>
        </div>
        <div>
          <div>Machine Learning Engineer</div>
          <p className="text-sm">Infocomm Media Development Authority, Nov 2018 - Jun 2019</p>
        </div>
      </div>
    } />
  )
}

function Projects() {
  return (
    <Layout content={
      <>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Startup</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Atlas</div>
                <div className="text-sm">AI Browser Assistant</div>
              </div>
              <div className="border rounded-md p-4">
                <div>AgentScale AI</div>
                <div className="text-sm">AI Automation Agency</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Stealth Startup</div>
                <div className="text-sm">Enterprise-grade AI Video Model</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Layla: AI Sales Rep</div>
                <div className="text-sm">Iterative Final Round Interview</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Research</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Transformer World Models<br />for Ad Hoc Teamwork</div>
                <div className="text-sm">Honours Dissertation</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Barlow Twins for Model-based Reinforcement Learning</div>
                <div className="text-sm">Turing Research Programme</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Coursework</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Multi-agent Reinforcement Learning for Slime Volleyball</div>
                <div className="text-sm">CS3244 1st Place at 17th NUS STePS</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Structured Multi-Agent World Models</div>
                <div className="text-sm">CS4246 RL Best Project</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Doodle-based Image Search</div>
                <div className="text-sm">CS4243 CV Best Project</div>
              </div>
              <div className="border rounded-md p-4">
                <div>DrFAQ</div>
                <div className="text-sm">CS4248 NLP Best Project</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Idle Trading Hero</div>
                <div className="text-sm">NUS Orbital Artemis Achievement</div>
              </div>
              <div className="border rounded-md p-4">
                <div>COVID Agent-Based Modelling</div>
                <div className="text-sm">HackerNews Front Page</div>
              </div>
              <div className="border rounded-md p-4">
                <div>This Gemstone Does Not Exist</div>
                <div className="text-sm">CS6101 Guest Freshman Project</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Hackathons</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Polypeptide Generation</div>
                <div className="text-sm">Won $1K USD at LLM4Bio Hackathon</div>
              </div>
              <div className="border rounded-md p-4">
                <div>The Chosen One: Multi-agent RL</div>
                <div className="text-sm">Best AI Hack at iNTUition Hackathon</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Carelytics</div>
                <div className="text-sm">HACKAsia Global Hackathon Finalist</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Optigram</div>
                <div className="text-sm">1st Runner Up</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-sm">Work</div>
            <div className="grid grid-cols-2 gap-2 w-full">
              <div className="border rounded-md p-4">
                <div>Indeed Feature Experimentation</div>
                <div className="text-sm">RecSys for {`>`}300M Job Seekers</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Grab Simkit</div>
                <div className="text-sm">Presented Google Developer Space</div>
              </div>
              <div className="border rounded-md p-4">
                <div>Chiller Doctor</div>
                <div className="text-sm">Secured $500K client deal</div>
              </div>
            </div>
          </div>
        </div>
      </>
    } />
  )
}

function Contact() {
  return (
    <Layout content={
      <>
        <p>Email me at <a href="mailto:jet@aetherlab.ai" className="hover:underline">jet@aetherlab.ai</a>.</p>
      </>
    } />
  )
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
